import React from "react"
import { Link, graphql } from "gatsby"

import { Navigation } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import configuration from "../configuration/category.json"

const Section = ({ children, title, description = null }) => (
  <div className="container mx-auto">
    <div className="relative px-4 pt-8 pb-20 sm:px-6 lg:pt-8 lg:pb-8 lg:px-8">
      <div className="relative mx-auto max-w-7xl">
        <div className="">
          <h1 className="font-serif text-3xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            {title}
          </h1>
          {description && (
            <p className="max-w-2xl mt-3 text-xl text-gray-500 sm:mt-4">
              {description}
            </p>
          )}
        </div>
        <div className="w-full mt-12">{children}</div>
      </div>
    </div>
  </div>
)

const Category = ({ data }) => {
  const articles = data.articles.edges
  const category = data.category

  const breadcrumbs = [
    { label: "Articles", url: "/articles/" },
    { label: category.name, url: `/${category.url}/` },
  ]

  return (
    <Layout background={"bg-gray-50"}>
      <SEO
        title={configuration.seo.title.replace("{}", category.name)}
        description={configuration.seo.description.replace("{}", category.name)}
        breadcrumbs={breadcrumbs}
      />
      <Navigation />
      <Breadcrumb breadcrumbs={breadcrumbs} />

      <Section
        title={configuration.section.articles.title.replace(
          "{}",
          category.name
        )}
        description={configuration.section.articles.description.replace(
          "{}",
          category.name.toLowerCase()
        )}
      >
        <div className="flex flex-col w-full">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody>
                    {articles.map(({ node }, index) => (
                      <Link
                        to={`/${node.frontmatter.url}/`}
                        className={`${
                          index % 2 === 0 ? "bg-white" : "bg-gray-50"
                        } hover:bg-gray-100 cursor-pointer`}
                        style={{
                          display: "table-row",
                        }}
                      >
                        <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                          {node.frontmatter.title}
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                          <div className="text-green-500 hover:text-green-600">
                            {configuration.section.articles.read}
                          </div>
                        </td>
                      </Link>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    articles: allMdx(
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            url
          }
        }
      }
    }
    category: categoriesJson(url: { eq: $category }) {
      id
      name
      url
    }
  }
`
